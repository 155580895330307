import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { environment } from '@env/environment';
import moment from 'moment-timezone';
import { HttpErrorResponse } from '@angular/common/http';
import { SentryService } from '@app/core/services/sentry.service';
import { throwError, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UpdateCurrentError } from '@app/core/store/app.action';

export class FDTHelper {
  static handleError(module: string, store: Store, router: Router, error: HttpErrorResponse) {
    SentryService.handleError(error);
    if (error.error instanceof ErrorEvent) {
      if (store) {
        store.dispatch(new UpdateCurrentError(error.error.message));
      }
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // check code
      if (error.status >= 400) {
        if (error.status === 401) {
          return router.navigate(['/auth/logout'], {
            replaceUrl: true,
          });
        } else if (error.error.message) {
          if (store) {
            store.dispatch(new UpdateCurrentError(error.error.message));
          }
          return throwError(error.error.message);
        }
      } else {
        if (store) {
          store.dispatch(new UpdateCurrentError(error));
        }
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${JSON.stringify(error.error)}`);
      }
    }
    // return an observable with a user-facing error message
    return throwError(
      `มีข้อผิดพลาดเกิดขึ้นในการเชื่อมต่อระบบ กรุณาลองใหม่ในอีกสักครู่ [${module}]`
      // 'Something bad happened; please try again later.'
    );
  }

  static parseHttpError(error) {
    if (typeof error === 'string') {
      return error;
    } else if (typeof error === 'object') {
      if (error.message) {
        return error.message;
      }
      return error;
    }
    return '';
  }
  static shouldShowErrorMessageContainer(form, field) {
    const fieldItem = form.controls[field];
    if (fieldItem.invalid && (fieldItem.dirty || fieldItem.touched)) {
      return true;
    }
    return false;
  }

  static shouldShowErrorMessage(form, field, validationType) {
    const control = form.get(field);
    return control.hasError(validationType) && (control.dirty || control.touched);
  }

  static validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach((ctrl: FormGroup) => {
          this.validateAllFormFields(ctrl);
        });
      }
    });
  }

  static getPdfUrl(pdfCode) {
    const t = Date.now();
    let os = null;
    if (window.liff) {
      os = window.liff.getOS();
    }
    return `${environment.apiDomain}/pdf-contents/file/${pdfCode}?t=${t}&os=${os}`;
  }

  static getUploadUrl(file) {
    const t = Date.now();
    return `${environment.baseUrl}/uploads/${file}?t=${t}`;
  }

  static getSecureUrl(lineId, url) {
    // const t = Date.now();
    const t = moment().format('X');
    // add tracking code
    if (url.indexOf('?') !== -1) {
      url += '&';
    } else {
      url += '?';
    }
    url += `cid=${environment.HCP_PORTAL_TRACKING_CODE}`;
    const encodedUrl = encodeURIComponent(url);
    return `${environment.apiDomain}/ocid/secure-url/${encodedUrl}?lineId=${lineId}&t=${t}`;
  }

  static getWebUrl(url) {
    const t = moment().format('X');
    const encodedUrl = encodeURIComponent(url);
    return `${environment.apiDomain}/data/r/${encodedUrl}?t=${t}`;
  }

  static getOCIDInitUrl() {
    return `${environment.apiDomain}/ocid/hcp/init`;
  }

  static getRouteForLiffMode(liffMode, squad, params = null) {
    const availableLiffMode = [
      'drug_availability',
      'sales_rep',
      'availability',
      'video_playback',
      'hcp_resources',
      'patient_resources',
      'reimbursement',
      'expert',
      'expert_faq',
      'ask_publication',
      'ask_experience',
      'ocid_test',
      'educational_materials',
      'faq',
      'ocid',
      'pdf',
      'home',
    ];
    const availabileSquads = [
      'Infection',
      'Ophthalmology',
      'Nephrology',
      'Hematology',
      'Gynecology',
      'HCC',
      'Breast',
      'Lung',
      'Foundation Medicine',
      'Other',
      'Public',
    ];
    if (availableLiffMode.indexOf(liffMode) === -1) {
      return null;
    }
    if (['ocid', 'pdf', 'home'].indexOf(liffMode) === -1 && availabileSquads.indexOf(squad) === -1) {
      return null;
    }
    if (liffMode === 'home') {
      return ['/hcp/home'];
    } else if (liffMode === 'ocid' && params && params.url) {
      return ['/hcp/liff/ocid', params.url ];
    } else if (liffMode === 'pdf' && params && params.url) {
      return ['/hcp/liff/pdf', params.url ];
    } else {
      return ['/hcp/liff/', liffMode, squad ];
    }
    /* if (liffMode === 'drug_availability') {
      return ['/hcp/liff/drug_availability/', squad ];
    } else if (liffMode === 'sales_rep') {
      return ['/hcp/liff/sales_rep/', squad ];
    } else if (liffMode === 'availability') {
      return ['/hcp/liff/availability/', squad ];
    } else if (liffMode === 'video_playback') {
      return ['/hcp/liff/video_playback/', squad ];
    } else if (liffMode === 'hcp_resources') {
      return ['/hcp/liff/hcp_resources/', squad ];
    } else if (liffMode === 'patient_resources') {
      return ['/hcp/liff/patient_resources/', squad ];
    } else if (liffMode === 'reimbursement') {
      return ['/hcp/liff/reimbursement/', squad ];
    } else if (liffMode === 'expert') {
      return ['/hcp/liff/expert/', squad ];
    } else if (liffMode === 'expert_faq') {
      return ['/hcp/liff/expert_faq/', squad ];
    } */
  }

  static getRichmenuName(richMenuId) {
    switch (richMenuId) {
      case environment.RICH_MENU_GENERIC:
        return 'Public Default';
      break;
      case environment.RICH_MENU_DEFAULT:
        return 'HCP Default';
      break;
      case environment.RICH_MENU_HEME:
        return 'Hematology';
      break;
      case environment.RICH_MENU_FMI:
        return 'FMI';
      break;
      case environment.RICH_MENU_OPHTHAL:
        return 'Ophthalmology';
      break;
      case environment.RICH_MENU_NEPHRO:
        return 'Nephology';
      break;
      case environment.RICH_MENU_HCC:
        return 'HCC';
      break;
      case environment.RICH_MENU_BREAST:
        return 'Breast';
      break;
      case environment.RICH_MENU_LUNG:
        return 'Lung';
      break;
      default:
        return 'Public Default';
      break;
    }
  }

  static dataURItoBlob(dataURI) {
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const base64String = dataURI.split(',')[1];
    // separate out the mime component
    // const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    return FDTHelper.base64toBlob(base64String);
  }

  static base64toBlob(base64String) {
    const byteString = atob(base64String);
    // convert base64 to raw binary data held in a string
    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
    }
    // write the ArrayBuffer to a blob, and you're done
    const bb = new Blob([ab]);
    return bb;
  }

  static getDANameFromRichmenu(richmenu) {
    switch (richmenu) {
      case environment.RICH_MENU_DEFAULT:
        return 'HCP';
      case environment.RICH_MENU_INFECTION:
        return 'Infeciton';
      case environment.RICH_MENU_NEPHRO:
        return 'Nephrology';
      case environment.RICH_MENU_HEME:
        return 'Hematology';
      case environment.RICH_MENU_FMI:
        return 'FMI';
      case environment.RICH_MENU_ONCOLOGY:
        return 'Oncology';
      case environment.RICH_MENU_HCC:
        return 'HCC';
      case environment.RICH_MENU_BREAST:
        return 'Breast';
      case environment.RICH_MENU_LUNG:
        return 'Lung';
      case environment.RICH_MENU_OPHTHAL:
        return 'Ophthalmology'
      case environment.RICH_MENU_GENERIC:
        return 'Public';
      default:
        return 'UNDEFINED';
    }
  }
}
