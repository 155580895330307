import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { environment } from '@env/environment';
import { throwError, Observable } from 'rxjs';
import { AppState } from '../store/app.state';
import { catchError, tap } from 'rxjs/operators';
import moment from 'moment-timezone';
import { SaveCurrentHCPInfo } from '../store/app.action';
import { SentryService } from './sentry.service';
import { FDTHelper } from '@app/shared/helpers/helpers';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HcpService {
  private baseUrl: string;
  private identity: any;

  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router,
  ) {
    this.baseUrl = environment.apiDomain;
  }

  private handleError(error: HttpErrorResponse) {
    return FDTHelper.handleError('hcp', this.store, this.router, error);
    /* SentryService.handleError(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // check code
      if (error.status >= 400) {
        if (error.error.message) {
          return throwError(error.error.message);
        }
      } else {
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${JSON.stringify(error.error)}`);
      }
    }
    // return an observable with a user-facing error message
    return throwError(
      'มีข้อผิดพลาดเกิดขึ้นในการเชื่อมต่อระบบ กรุณาลองใหม่ในอีกสักครู่ [hcp]'
      // 'Something bad happened; please try again later.'
    ); */
  }

  // register a new HCP
  create(payload, lineIdToken): Observable<any> {
    const url = `${this.baseUrl}/hcp/register?t=${moment().format('X')}`;
    return this.http.post(url, payload, {
        headers: {
          Authorization: `Bearer ${lineIdToken}`,
          'ngrok-skip-browser-warning': 'true',
        }
      }).pipe(
        catchError(this.handleError.bind(this))
      );
  }

  // update HCP info
  update(payload, lineIdToken): Observable<any> {
    const url = `${this.baseUrl}/hcp/register?t=${moment().format('X')}`;
    return this.http.put(url, payload, {
        headers: {
          Authorization: `Bearer ${lineIdToken}`,
          'ngrok-skip-browser-warning': 'true',
        }
      }).pipe(
        catchError(this.handleError.bind(this))
      );
  }

  listHCPList(query) {
    let url = `${this.baseUrl}/admin-hcp?t=${moment().format('X')}`;
    const queryList = [
      [
        'page=' + (query.page || 1),
      ],
      [
        'page_size=' + (query.page_size || 25),
      ],
      [
        'q=' + (query.q ? encodeURIComponent(query.q) : ''),
      ],
      [
        'profession=' + (query.profession || ''),
      ],
      [
        'specialty=' + (query.specialty ? encodeURIComponent(query.specialty).replace(/\(/g, '%28').replace(/\)/g, '%29') : ''),
      ],
      [
        'status=' + (query.status || ''),
      ],
    ];
    url += '&' + queryList.join('&');
    return this.http.get(url).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  loadHCPInfo(hcpId) {
    const url = `${this.baseUrl}/admin-hcp/${hcpId}?t=${moment().format('X')}`;
    return this.http.get(url).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  markAsPendingConfirm(hcpId) {
    const url = `${this.baseUrl}/admin-hcp/${hcpId}/confirmation/pending?t=${moment().format('X')}`;
    return this.http.post(url, null).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  sendsendVerifyEmail(hcpId) {
    const url = `${this.baseUrl}/admin-hcp/${hcpId}/confirmation/verify?t=${moment().format('X')}`;
    return this.http.post(url, null).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  sendActivationNotified(hcpId) {
    const url = `${this.baseUrl}/admin-hcp/${hcpId}/confirmation/notify?t=${moment().format('X')}`;
    return this.http.post(url, null).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  markAsConfirmed(hcpId) {
    const url = `${this.baseUrl}/admin-hcp/${hcpId}/confirmation?t=${moment().format('X')}`;
    return this.http.post(url, null).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  markAsRejected(hcpId) {
    const url = `${this.baseUrl}/admin-hcp/${hcpId}/confirmation?t=${moment().format('X')}`;
    return this.http.delete(url).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  markAsRocheStaff(hcpId, flag) {
    const action = flag ? 'mark' : 'unmark';
    const url = `${this.baseUrl}/admin-hcp/${hcpId}/roche-staff/${action}?t=${moment().format('X')}`;
    return this.http.post(url, null).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  markAsExcludedOCEP(hcpId, flag) {
    const action = flag ? 'mark' : 'unmark';
    const url = `${this.baseUrl}/admin-hcp/${hcpId}/exclude-ocep/${action}?t=${moment().format('X')}`;
    return this.http.post(url, null).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  deleteHCP(hcpId) {
    const url = `${this.baseUrl}/admin-hcp/${hcpId}?t=${moment().format('X')}`;
    return this.http.delete(url).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  setDefaultRichmenu(lineIdToken, richmenuId) {
    const url = `${this.baseUrl}/hcp/richmenu/${richmenuId}?t=${moment().format('X')}`;
    return this.http.post(url, null, {
      headers: {
        Authorization: `Bearer ${lineIdToken}`,
        'ngrok-skip-browser-warning': 'true',
      }
    }).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  loadIdentity(lineIdToken) {
    const url = `${this.baseUrl}/hcp/me?t=${moment().format('X')}`;
    return this.http.get(url, {
      headers: {
        Authorization: `Bearer ${lineIdToken}`,
        'ngrok-skip-browser-warning': 'true',
      }
    }).pipe(
      catchError(async () => {
        await this.store.dispatch(new SaveCurrentHCPInfo(null));
        this.handleError.bind(this);
      }),
      tap(async (identity) => {
        this.identity = identity;
        await this.store.dispatch(new SaveCurrentHCPInfo(identity));
      })
    );
  }

  hasIdentity() {
    return this.getIdentity() !== null;
  }

  getIdentity() {
    this.identity = this.store.selectSnapshot(AppState.currentHCP) || null;
    return this.identity;
  }

  askPublication(payload, lineIdToken): Observable<any> {
    const url = `${this.baseUrl}/hcp/ask-publication?t=${moment().format('X')}`;
    return this.http.post(url, payload, {
        headers: {
          Authorization: `Bearer ${lineIdToken}`,
          'ngrok-skip-browser-warning': 'true',
        }
      }).pipe(
        catchError(this.handleError.bind(this))
      );
  }
  listHCPAskPublication(query) {
    let url = `${this.baseUrl}/admin-hcp/requests/ask-publication?t=${moment().format('X')}`;
    const queryList = [
      [
        'page=' + (query.page || 1),
      ],
      [
        'page_size=' + (query.page_size || 25),
      ],
      [
        'q=' + (query.q || ''),
      ],
      [
        'squad=' + (query.squad || 'ALL'),
      ],
      [
        'status=' + (query.status || 'ALL'),
      ],
    ];
    url += '&' + queryList.join('&');
    return this.http.get(url).pipe(
      catchError(this.handleError.bind(this))
    );
  }
  markAskPublicationResolve(id) {
    const url = `${this.baseUrl}/admin-hcp/requests/ask-publication/${id}/resolve?t=${moment().format('X')}`;
    return this.http.post(url, null).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  markAskPublicationUnresolve(id) {
    const url = `${this.baseUrl}/admin-hcp/requests/ask-publication/${id}/resolve?t=${moment().format('X')}`;
    return this.http.delete(url).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  exportAdmin(): Observable<any> {
    const url = `${this.baseUrl}/admin-hcp/export?t=${moment().format('X')}`;
    return this.http.post(url, null).pipe(
      catchError(this.handleError)
    );
  }

  reactivateHCPMenu(lineIdToken): Observable<any>  {
    const url = `${this.baseUrl}/hcp/reactivate-hcp-menu?t=${moment().format('X')}`;
    return this.http.post(url, null, {
        headers: {
          Authorization: `Bearer ${lineIdToken}`,
          'ngrok-skip-browser-warning': 'true',
        }
      }).pipe(
        catchError(this.handleError.bind(this))
      );
  }

  startChat(lineIdToken): Observable<any>  {
    const url = `${this.baseUrl}/hcp/start-chat?t=${moment().format('X')}`;
    return this.http.post(url, null, {
        headers: {
          Authorization: `Bearer ${lineIdToken}`,
          'ngrok-skip-browser-warning': 'true',
        }
      }).pipe(
        catchError(this.handleError.bind(this))
      );
  }

  updateHCP(hcpId, payload): Observable<any> {
    const url = `${this.baseUrl}/admin-hcp/${hcpId}/update?t=${moment().format('X')}`;
    return this.http.post(url, payload).pipe(
      catchError(this.handleError)
    );
  }
}
