const apiBaseUrl = 'https://rochethlineoa.com';
const apiDomain = `${apiBaseUrl}/api/v1`;
const baseUrl = 'https://rochethlineoa.com';

export const environment = {
  env: 'PRD',
  applicationName: 'Roche TH LINE OA',
  defaultPageTitle: 'Roche TH LINE OA',
  production: true,
  apiDomain,
  baseUrl,
  rocheConnectHost: 'rocheconnect.com',
  rocheConnectProbeUrl: 'https://www.rocheconnect.com/home.html',
  TIMEZONE: 'Asia/Bangkok',
  LINE_LIFF_ID: '1654210606-d1qQ264p',
  LINE_RICHMENU_LIFF_ID: '1654210606-J09jgxEP',
  LINE_ID_TOKEN_DUMMY: '',
  // RICH_MENU_GENERIC: 'richmenu-1204922b8870053bd02204fc8a989717',
  RICH_MENU_GENERIC: 'richmenu-155c04e307630269e9bbf121d07bcea6',
  // RICH_MENU_DEFAULT: 'richmenu-d60af8d9af32c0c5be41f09d093500ac',
  RICH_MENU_DEFAULT: 'richmenu-65d640868828061a5c59b9a8565ed803',
  RICH_MENU_INFECTION: 'richmenu-d1c5e6e798d760a6540a67434f318c42',
  RICH_MENU_NEPHRO: 'richmenu-f60868ee426a4891e17b652c9ad2cac4',
  RICH_MENU_HEME: 'richmenu-935f10e8ee1f79f7c966c0870bab95be',
  RICH_MENU_FMI: 'richmenu-ae5d3be1d2bd89ba48b217e487573a07',
  RICH_MENU_ONCOLOGY: 'richmenu-f006c8e90cbdd43056183c1380ee41a6',
  RICH_MENU_HCC: 'richmenu-fcc61ce8d2fa47e4e60b04d3f8fffbd4',
  RICH_MENU_BREAST: 'richmenu-b921be4565ede65bc21767468cd90055',
  RICH_MENU_LUNG: 'richmenu-6c45a2e243e44028072e2e493f1bf1fe',
  RICH_MENU_OPHTHAL: 'richmenu-5027cea5c552860e9df071930781070b',
  SENTRY_ENABLE: true,
  SENTRY_DSN: 'https://6bc2768a96c44e18876dd53664f01790@o390002.ingest.sentry.io/5230600',
  OCID_API_KEY: 'ebf22082-4b87-45f1-86ab-35250922800d',
  ACTIVITY_TIMER_INTERVAL: 5000,
  ACTIVITY_IDEL_TIMEOUT: 15 * 60, // seconds ==> 15 minutes
  // GA_TRACKING_CODE: 'UA-158623769-7',
  GA_TRACKING_CODE: 'G-QC0FZ4H12V',
  CHAT_REFRESH_INTERVAL: 10000, // 10 seconds
  uploadEndpoint: '/pdf-contents/upload',
  uploadMaxFileSize: 10, // 10 MB
  uploadFileSupports: [
    'application/pdf', // PDF
  ],
  HCP_PORTAL_TRACKING_CODE: 'afxxth2010Line',
};
