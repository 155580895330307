import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { AppState } from '../store/app.state';
import { SentryService } from '../services/sentry.service';

@Injectable()
export class OCIDSessionAppender implements HttpInterceptor {
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      req.url.indexOf(environment.rocheConnectHost) !== -1
    ) {
      const request = req.clone({
        withCredentials: true,
      });
      SentryService.captureMessage('Inject session cookie');
      return next.handle(request);
    }
    return next.handle(req);
  }
}
