import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppState } from '../store/app.state';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Injectable()
export class FDTErrorHandler implements HttpInterceptor {
  constructor(
    private store: Store,
    private router: Router,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      req.url.startsWith(document.location.origin) // local domain
      || req.url.startsWith('/') // relative
      || (environment.apiDomain && environment.apiDomain.trim().length > 0 &&
        req.url.startsWith(environment.apiDomain)) // is targeting API
    ) {
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse, caught: Observable<any>): Observable<any> => {
          const data: any = {
            message: error && error.error.message ? error.error.message : '',
            status: error.status
          };
          if (data.message === 'Invalid access token' || data.message.indexOf('Invalid session') !== -1) {
            this.router.navigate(['/auth/logout'], {
              replaceUrl: true,
            });
            return next.handle(req);
          }
          return throwError(error);
        })
      );
    }
    return next.handle(req);
  }
}
