export class AppAction {
}
export class SaveLiffMode {
  static readonly type = '[LIFF] SaveLiffMode';
  constructor(public liffMode: any) {}
}
export class SaveCurrentLiffInfo {
  static readonly type = '[LIFF] SaveCurrentLiffInfo';
  constructor(public liffInfo: any) {}
}
export class SaveCurrentLineIdToken {
  static readonly type = '[LIFF] SaveCurrentLineIdToken';
  constructor(public idToken: any) {}
}
export class SaveCurrentLineAccessToken {
  static readonly type = '[LIFF] SaveCurrentLineAccessToken';
  constructor(public accessToken: any) {}
}
export class SaveCurrentLineUserInfo {
  static readonly type = '[LIFF] SaveCurrentLineUserInfo';
  constructor(public userInfo: any) {}
}
export class SaveCurrentUserInfo {
  static readonly type = '[AUTH] SaveCurrentUserInfo';
  constructor(public user: any) {}
}
export class RemoveCurrentUserInfo {
  static readonly type = '[AUTH] RemoveCurrentUserInfo';
}
export class ShowGlobalSpinner {
  static readonly type = '[APP] ShowGlobalSpinner';
  constructor(public message: any = 'Loading...') {}
}
export class HideGlobalSpinner {
  static readonly type = '[APP] HideGlobalSpinner';
}
export class ShowMainNav {
  static readonly type = '[APP] ShowMainNav';
}
export class HideMainNav {
  static readonly type = '[APP] HideMainNav';
}
export class ToggleSideBar {
  static readonly type = '[APP] ToggleSideBar';
}
export class ShowGlobalHeader {
  static readonly type = '[APP] ShowGlobalHeader';
}
export class HideGlobalHeader {
  static readonly type = '[APP] HideGlobalHeader';
}
export class ShowSideBar {
  static readonly type = '[APP] ShowSideBar';
}
export class HideSideBar {
  static readonly type = '[APP] HideSideBar';
}
export class SaveCurrentHCPInfo {
  static readonly type = '[HCP] SaveCurrentHCPInfo';
  constructor(public hcp: any) {}
}
export class RemoveCurrentHCPInfo {
  static readonly type = '[HCP] RemoveCurrentHCPInfo';
}
export class UpdateLastActivityTimestamp {
  static readonly type = '[APP] UpdateLastActivityTimestamp';
  constructor(public timestamp: number) {}
}
export class UpdateCurrentError {
  static readonly type = '[APP] UpdateCurrentError';
  constructor(public error: any) {}
}
