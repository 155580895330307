import { State, Selector, Action, StateContext } from '@ngxs/store';
import {
  SaveLiffMode,
  SaveCurrentUserInfo,
  RemoveCurrentUserInfo,
  SaveCurrentLiffInfo,
  SaveCurrentLineUserInfo,
  SaveCurrentLineIdToken,
  SaveCurrentLineAccessToken,
  ShowGlobalSpinner,
  HideGlobalSpinner,
  ShowMainNav,
  HideMainNav,
  SaveCurrentHCPInfo,
  RemoveCurrentHCPInfo,
  ShowGlobalHeader,
  HideGlobalHeader,
  ToggleSideBar,
  ShowSideBar,
  HideSideBar,
  UpdateLastActivityTimestamp,
  UpdateCurrentError,
} from './app.action';
import { CurrentLiffInfo } from '@app/shared/models/current-liff-info';
import { CurrentLineUserInfo } from '@app/shared/models/current-line-user-info';

export class AppStateModel {
  config: any;
  currentLiffMode: string;
  currentLiffInfo: CurrentLiffInfo;
  currentLineUserInfo: any;
  currentLineIdToken: string;
  currentLineAccessToken: string;
  currentUser: any;
  shouldShowSpinner: boolean;
  globalSpinnerMessage: string;
  shouldShowMainNav: boolean;
  shouldShowGlobalHeader: boolean;
  shouldShowSideBar: boolean;
  currentHCP: any;
  lastActivityTimestamp: any;
  currentError: any;
  static defaults() {
    return {
      config: null,
      currentLiffMode: null,
      currentLiffInfo: null,
      currentLineUserInfo: null,
      currentLineIdToken: null,
      currentLineAccessToken: null,
      currentUser: null,
      shouldShowSpinner: false,
      globalSpinnerMessage: 'Loading...',
      shouldShowMainNav: false,
      shouldShowGlobalHeader: false,
      shouldShowSideBar: false,
      currentHCP: null,
      lastActivityTimestamp: -1,
      currentError: null,
    };
  }
}

@State<AppStateModel>({
  name: 'carrot_hcp_liff',
  defaults: AppStateModel.defaults(),
})
export class AppState {
  @Selector()
  static currentUser(state: AppStateModel) { return state.currentUser; }
  @Selector()
  static currentLiffMode(state: AppStateModel) { return state.currentLiffMode; }
  @Selector()
  static currentLiffInfo(state: AppStateModel) { return state.currentLiffInfo; }
  @Selector()
  static currentLineUserInfo(state: AppStateModel) { return state.currentLineUserInfo; }
  @Selector()
  static currentLineIdToken(state: AppStateModel) { return state.currentLineIdToken; }
  @Selector()
  static currentLineAccessToken(state: AppStateModel) { return state.currentLineAccessToken; }
  @Selector()
  static shouldShowSpinner(state: AppStateModel) { return state.shouldShowSpinner; }
  @Selector()
  static globalSpinnerMessage(state: AppStateModel) { return state.globalSpinnerMessage; }
  @Selector()
  static shouldShowMainNav(state: AppStateModel) { return state.shouldShowMainNav; }
  @Selector()
  static currentHCP(state: AppStateModel) { return state.currentHCP; }
  @Selector()
  static shouldShowGlobalHeader(state: AppStateModel) { return state.shouldShowGlobalHeader; }
  @Selector()
  static shouldShowSideBar(state: AppStateModel) { return state.shouldShowSideBar; }
  @Selector()
  static lastActivityTimestamp(state: AppStateModel) { return state.lastActivityTimestamp; }
  @Selector()
  static currentError(state: AppStateModel) { return state.currentError; }

  @Action(SaveLiffMode)
  SaveLiffMode({ patchState }: StateContext<AppStateModel>, action: SaveLiffMode) {
    patchState({
      currentLiffMode: action.liffMode,
    });
  }
  @Action(SaveCurrentLiffInfo)
  SaveCurrentLiffInfo({ patchState }: StateContext<AppStateModel>, action: SaveCurrentLiffInfo) {
    patchState({
      currentLiffInfo: action.liffInfo,
    });
  }
  @Action(SaveCurrentLineUserInfo)
  SaveCurrentLineUserInfo({ patchState }: StateContext<AppStateModel>, action: SaveCurrentLineUserInfo) {
    patchState({
      currentLineUserInfo: action.userInfo,
    });
  }
  @Action(SaveCurrentLineIdToken)
  SaveCurrentLineIdToken({ patchState }: StateContext<AppStateModel>, action: SaveCurrentLineIdToken) {
    patchState({
      currentLineIdToken: action.idToken,
    });
  }
  @Action(SaveCurrentLineAccessToken)
  SaveCurrentLineAccessToken({ patchState }: StateContext<AppStateModel>, action: SaveCurrentLineAccessToken) {
    patchState({
      currentLineAccessToken: action.accessToken,
    });
  }
  @Action(SaveCurrentUserInfo)
  SaveCurrentUserInfo({ patchState }: StateContext<AppStateModel>, action: SaveCurrentUserInfo) {
    patchState({
      currentUser: action.user,
    });
  }
  @Action(RemoveCurrentUserInfo)
  RemoveCurrentUserInfo({ patchState }: StateContext<AppStateModel>, action: RemoveCurrentUserInfo) {
    patchState({
      currentUser: null,
    });
  }
  @Action(ShowGlobalSpinner)
  ShowGlobalSpinner({ patchState }: StateContext<AppStateModel>, action: ShowGlobalSpinner) {
    patchState({
      shouldShowSpinner: true,
      globalSpinnerMessage: action.message,
    });
  }
  @Action(HideGlobalSpinner)
  HideGlobalSpinner({ patchState }: StateContext<AppStateModel>, action: HideGlobalSpinner) {
    patchState({
      shouldShowSpinner: false,
    });
  }
  @Action(ShowMainNav)
  ShowMainNav({ patchState }: StateContext<AppStateModel>, action: ShowMainNav) {
    patchState({
      shouldShowMainNav: true,
    });
  }
  @Action(HideMainNav)
  HideMainNav({ patchState }: StateContext<AppStateModel>, action: HideMainNav) {
    patchState({
      shouldShowMainNav: false,
    });
  }
  @Action(SaveCurrentHCPInfo)
  SaveCurrentHCPInfo({ patchState }: StateContext<AppStateModel>, action: SaveCurrentHCPInfo) {
    patchState({
      currentHCP: action.hcp,
    });
  }
  @Action(RemoveCurrentHCPInfo)
  RemoveCurrentHCPInfo({ patchState }: StateContext<AppStateModel>, action: RemoveCurrentHCPInfo) {
    patchState({
      currentHCP: null,
    });
  }
  @Action(ShowGlobalHeader)
  ShowGlobalHeader({ patchState }: StateContext<AppStateModel>, action: ShowGlobalHeader) {
    patchState({
      shouldShowGlobalHeader: true,
    });
  }
  @Action(HideGlobalHeader)
  HideGlobalHeader({ patchState }: StateContext<AppStateModel>, action: HideGlobalHeader) {
    patchState({
      shouldShowGlobalHeader: false,
      shouldShowSideBar: false,
    });
  }
  @Action(ToggleSideBar)
  ToggleSideBar({ patchState, getState }: StateContext<AppStateModel>, action: ToggleSideBar) {
    const currentState = getState();
    patchState({
      shouldShowSideBar: !currentState.shouldShowSideBar,
    });
  }
  @Action(ShowSideBar)
  ShowSideBar({ patchState }: StateContext<AppStateModel>, action: ShowSideBar) {
    patchState({
      shouldShowSideBar: true,
    });
  }
  @Action(HideSideBar)
  HideSideBar({ patchState }: StateContext<AppStateModel>, action: HideSideBar) {
    patchState({
      shouldShowSideBar: false,
    });
  }
  @Action(UpdateLastActivityTimestamp)
  UpdateLastActivityTimestamp({ patchState }: StateContext<AppStateModel>, action: UpdateLastActivityTimestamp) {
    patchState({
      lastActivityTimestamp: action.timestamp,
    });
  }
  @Action(UpdateCurrentError)
  UpdateCurrentError({ patchState }: StateContext<AppStateModel>, action: UpdateCurrentError) {
    patchState({
      currentError: action.error
    });
  }
}
