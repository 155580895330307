import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { AppState } from '../store/app.state';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment-timezone';

@Injectable()
export class JwtTokenAppender implements HttpInterceptor {
  constructor(
    private store: Store,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      req.url.startsWith(document.location.origin) // local domain
      || req.url.startsWith('/') // relative
      || (environment.apiDomain && environment.apiDomain.trim().length > 0 &&
        req.url.startsWith(environment.apiDomain)) // is targeting API
    ) {
      // except HCP & OCID
      if (req.url.includes('/hcp/') || req.url.includes('/ocid/')) {
        return next.handle(req);
      }
      const currentUser = this.store.selectSnapshot(AppState.currentUser);
      let token = '';
      if (currentUser && currentUser.access_token) {
        token = currentUser.access_token;
      }
      if (token !== '') {
        // verify token before sending
        let tokenValid = false;
        try {
          const tokenContent = jwt_decode(token);
          if (tokenContent) {
            const tokenExpiryDate = moment(tokenContent.exp, 'X').tz(environment.TIMEZONE);
            const now = moment().tz(environment.TIMEZONE);
            if (tokenExpiryDate.diff(now) > 0) {
              tokenValid = true;
            }
          }
        } catch (e) {
          console.error(e);
        }
        if (!tokenValid) {
          // return throwError('Invalid session JWT token');
          return throwError({
            status: 401,
            message: 'Invalid session JWT token'
          });
        }
        const headers: any = {};
        headers.Authorization = `Bearer ${token}`;
        const request = req.clone({
          setHeaders: headers,
        });
        return next.handle(request);
      } else {
        return next.handle(req);
      }
    }
    return next.handle(req);
  }
}
