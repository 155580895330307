import { Injectable } from '@angular/core';

import * as Sentry from '@sentry/browser';
import { environment } from '@env/environment';
import { CurrentLiffInfo } from '@app/shared/models/current-liff-info';

Sentry.init({
  dsn: environment.SENTRY_DSN,
  environment: environment.env,
});

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  constructor() { }

  static captureMessage(message) {
    if (environment.SENTRY_ENABLE) {
      try {
        Sentry.captureMessage(message, Sentry.Severity.Info);
      } catch (e) {
        console.log(e);
      }
    }
  }

  static handleError(error) {
    if (environment.SENTRY_ENABLE) {
      try {
        console.log('Sending error to Sentry: ', error);
        const eventId = Sentry.captureException(error.originalError || error);
        console.log('Sentry logged with eventId: ', eventId);
        // Sentry.showReportDialog({ eventId });
      } catch (e) {
        console.log(e);
      }
    }
  }
  static handleLIFFError(error) {
    Sentry.withScope(async (scope) => {
      const liff = window.liff;
      if (liff) {
        const liffInfo = new CurrentLiffInfo();
        liffInfo.browserLanguage = liff.getLanguage();
        liffInfo.sdkVersion = liff.getVersion();
        liffInfo.lineVersion = liff.getVersion();
        liffInfo.isInClient = liff.isInClient();
        liffInfo.isLoggedIn = liff.isLoggedIn();
        liffInfo.deviceOS = liff.getOS();
        Sentry.setExtra('liff_info', liffInfo);
        try {
          const lineUserInfo = await liff.getProfile();
          if (lineUserInfo) {
            Sentry.setUser({
              username: lineUserInfo.name,
            });
          }
        } catch (e) {}
      }
      try {
        SentryService.handleError(error);
      } catch (e) {
        console.log(e);
      }
    });
  }
}
