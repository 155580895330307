import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injectable, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@app/core/core.module';
import { AppMaterialModule } from '@app/shared/app-material/app-material.module';
import { JwtTokenAppender } from '@app/core/http/jwt-token-appender';

// NGXS
import { NgxsModule, Store } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { AppState } from '@app/core/store/app.state';
import { FDTErrorHandler } from '@app/core/http/error-handler';
import { environment } from '@env/environment';
import { CurrentLiffInfo } from './shared/models/current-liff-info';
import {
  SaveCurrentLiffInfo,
  SaveCurrentLineIdToken,
  SaveCurrentLineUserInfo,
  ShowGlobalSpinner,
  SaveCurrentLineAccessToken,
  SaveLiffMode
} from './core/store/app.action';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';

import * as Sentry from '@sentry/browser';
import { ConfirmationDialogComponent } from './shared/components/confirmation-dialog/confirmation-dialog.component';
import { CreateNewUserDialogComponent } from './shared/components/create-new-user-dialog/create-new-user-dialog.component';
import { Router } from '@angular/router';
import { SentryService } from './core/services/sentry.service';
import { OCIDSessionAppender } from './core/http/ocid-session-appender';
import { OcidService } from './core/services/ocid.service';

import { Angulartics2Module } from 'angulartics2';
import { PdfContentFormDialogComponentComponent } from './shared/components/pdf-content-form-dialog-component/pdf-content-form-dialog-component.component';

import {
  DropzoneModule,
  DROPZONE_CONFIG,
  DropzoneConfigInterface
} from 'ngx-dropzone-wrapper';
import { ExportChatOptionDialogComponent } from './shared/components/export-chat-option-dialog/export-chat-option-dialog.component';

import qs from 'qs';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: `${environment.apiDomain}${environment.uploadEndpoint}`,
  paramName: 'files',
  maxFilesize: environment.uploadMaxFileSize,
  acceptedFiles: environment.uploadFileSupports.join(','),
  dictDefaultMessage: '',
  createImageThumbnails: false,
};

Sentry.init({
  dsn: environment.SENTRY_DSN,
  environment: environment.env,
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    console.error(error);
    if (environment.SENTRY_ENABLE) {
      const eventId = Sentry.captureException(error.originalError || error);
      console.log('Sentry logged with eventId: ', eventId);
      // Sentry.showReportDialog({ eventId });
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    // ExportChatOptionDialogComponent,
    // PdfContentFormDialogComponentComponent,
    // CreateNewUserDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    CoreModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DropzoneModule,
    // State Management
    NgxsModule.forRoot([
      AppState,
    ], {
      developmentMode: !environment.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [
        'carrot_hcp_liff',
      ]
    }),
    Angulartics2Module.forRoot({
      gst: {
        trackingIds: [environment.GA_TRACKING_CODE],
        customMap: {
          dimension1: 'user_id',
          dimension2: 'recent_active',
          dimension3: 'specialty',
          dimension4: 'profession',
          dimension5: 'status',
          dimension6: 'default_richmenu',
          dimension7: 'line_user_id',
        },
      },
      pageTracking: {
        excludedRoutes: [
          new RegExp('^admin\/*', 'gi'),
        ],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: onAppInit,
      deps: [
        OcidService,
        Store,
        Router,
        NgxSpinnerService,
      ],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtTokenAppender,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OCIDSessionAppender,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FDTErrorHandler,
      multi: true,
    },
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG,
    }
    /* {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    } */
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function addGSTSourceScriptToHead() {
  const head = document.getElementsByTagName('head')[0];

  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.GA_TRACKING_CODE}`;
  head.insertBefore(script, head.firstChild);
}

export function addGSTScriptToHead() {
  const head = document.getElementsByTagName('head')[0];

  const script = document.createElement('script');
  script.innerHTML = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${environment.GA_TRACKING_CODE}', { 'debug_mode':true });`;
  head.insertBefore(script, head.firstChild);
}

export function onAppInit(
  ocidService: OcidService,
  store: Store,
  router: Router,
) {
  return async () => {
    // window.ga('create', environment.GA_TRACKING_CODE, 'auto');
    addGSTScriptToHead();
    addGSTSourceScriptToHead();
    const pathname = window.location.pathname;
    let LINE_LIFF_ID = environment.LINE_LIFF_ID;
    // check path
    const queryString = decodeURIComponent(window.location.search)
      .replace('?liff.state=', '');
    if (queryString && queryString.indexOf('?richmenu=') !== -1) {
      store.dispatch(new SaveLiffMode('RICH_MENU'));
      LINE_LIFF_ID = environment.LINE_RICHMENU_LIFF_ID;
    }
    // try parsing for richmenu first
    /* const qsComps = qs.parse(window.location.search.substring(1));
    let queryString = '';
    if (qsComps && qsComps['liff.state']) {
      const rawQueryString = decodeURIComponent(qsComps['liff.state'])
      queryString = rawQueryString.substring(rawQueryString.indexOf('?') + 1);
    }
    if (queryString) {
      const params = qs.parse(queryString);
      let shouldSetLIFFForRichmenu = false;
      if (params && params.richmenu) {
        shouldSetLIFFForRichmenu = true;
      }
      else if (params && params.u && params.u.indexOf('richmenu=') !== -1) {
        // chceck if URL is tracking for richmenu
        shouldSetLIFFForRichmenu = true;
      }
      // check if params contains "richmenu" or not
      if (shouldSetLIFFForRichmenu) {
        store.dispatch(new SaveLiffMode('RICH_MENU'));
        LINE_LIFF_ID = environment.LINE_RICHMENU_LIFF_ID;
      }
    } else {
      return;
    } */
    await store.dispatch(new ShowGlobalSpinner());
    // init LIFF App
    return new Promise<void>((resolve) => {
      if (window.liff) {
        const liff = window.liff;
        /* if (environment.LIFF_INSPECTOR_URL) {
          liff.use(new LIFFInspectorPlugin({
            origin: environment.LIFF_INSPECTOR_URL,
          }));
        } */
        liff
          .init({
            liffId: LINE_LIFF_ID,
          })
          .then(async () => {
            if (liff.isInClient()) {
              try {
                // clear data
                await store.dispatch(new SaveCurrentLineIdToken(null));
                await store.dispatch(new SaveCurrentLineAccessToken(null));
                await store.dispatch(new SaveCurrentLineUserInfo(null));
                // get LIFF Info
                const liffInfo = new CurrentLiffInfo();
                liffInfo.browserLanguage = liff.getLanguage();
                liffInfo.sdkVersion = liff.getVersion();
                liffInfo.lineVersion = liff.getVersion();
                liffInfo.isInClient = liff.isInClient();
                liffInfo.isLoggedIn = liff.isLoggedIn();
                liffInfo.deviceOS = liff.getOS();
                await store.dispatch(new SaveCurrentLiffInfo(liffInfo));
                let idToken = null;
                let accessToken = null;
                let lineUserInfo = null;
                console.log('liff.isLoggedIn', liff.isLoggedIn());
                if (liff.isLoggedIn()) {
                  idToken = liff.getIDToken();
                  accessToken = liff.getAccessToken();
                  lineUserInfo = await liff.getProfile();
                } else {
                  SentryService.captureMessage('LIFF not logged in');
                }
                await store.dispatch(new SaveCurrentLineIdToken(idToken));
                await store.dispatch(new SaveCurrentLineAccessToken(accessToken));
                await store.dispatch(new SaveCurrentLineUserInfo(lineUserInfo));
              } catch (e) {
                console.error(e);
                resolve();
              }
            }
            resolve();
          })
          .catch((err) => {
            if (err.message === 'cancelled') {
              return;
            }
            if (err.message.toLowerCase() === 'failed to fetch') {
              SentryService.handleError(err);
              return;
            }
            console.log('LIFF error: ', err);
            alert('Error: ' + err.message);
            if (liff.isInClient()) {
              SentryService.handleLIFFError(err);
            } else {
              SentryService.handleError(err);
              console.error('[LIFF INIT]', err);
            }
            resolve();
          })
          .finally(() => {
            resolve();
          });
      } else {
        resolve();
      }
    });
  };
}
