import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import moment from 'moment-timezone';
import { Store } from '@ngxs/store';
import { AppState } from '../store/app.state';
import { SaveCurrentUserInfo, RemoveCurrentUserInfo } from '../store/app.action';
import { SentryService } from './sentry.service';
import { FDTHelper } from '@app/shared/helpers/helpers';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl: string;
  private identity: any;

  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router,
  ) {
    this.baseUrl = environment.apiDomain;
  }

  private handleError(error: HttpErrorResponse) {
    return FDTHelper.handleError('auth', this.store, this.router, error);
    /* SentryService.handleError(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // check code
      if (error.status >= 400) {
        if (error.error.message) {
          return throwError(error.error.message);
        }
      } else {
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${JSON.stringify(error.error)}`);
      }
    }
    // return an observable with a user-facing error message
    return throwError(
      'มีข้อผิดพลาดเกิดขึ้นในการเชื่อมต่อระบบ กรุณาลองใหม่ในอีกสักครู่ [auth]'
      // 'Something bad happened; please try again later.'
    ); */
  }

  create(payload): Observable<any> {
    const url = `${this.baseUrl}/users?t=${moment().format('X')}`;
    return this.http.post(url, payload).pipe(
        catchError(this.handleError)
      );
  }

  forgetPassword(payload): Observable<any> {
    const url = `${this.baseUrl}/users/forget-password?t=${moment().format('X')}`;
    return this.http.post(url, payload).pipe(
        catchError(this.handleError)
      );
  }

  resetPassword(payload): Observable<any> {
    const url = `${this.baseUrl}/users/reset-password?t=${moment().format('X')}`;
    return this.http.post(url, payload).pipe(
        catchError(this.handleError)
      );
  }

  login(payload): Observable<any> {
    const url = `${this.baseUrl}/users/authenticate?t=${moment().format('X')}`;
    return this.http.post(url, payload).pipe(
      catchError(this.handleError),
      tap((identity) => {
        this.identity = identity;
        this.store.dispatch(new SaveCurrentUserInfo(identity));
      })
    );
  }

  loadIdentity() {
    const url = `${this.baseUrl}/users/me?t=${moment().format('X')}`;
    return this.http.get(url).pipe(
      catchError(this.handleError),
      tap((identity) => {
        this.identity = identity;
        this.store.dispatch(new SaveCurrentUserInfo(identity));
      })
    );
  }

  logout(): Observable<any> {
    const url = `${this.baseUrl}/users/authenticate?t=${moment().format('X')}`;
    return this.http.delete(url).pipe(
      catchError(this.handleError),
      tap((identity) => {
        this.identity = identity;
        this.store.dispatch(new RemoveCurrentUserInfo());
      })
    );
  }

  hasIdentity() {
    return this.getIdentity() !== null;
  }

  getIdentity() {
    this.identity = this.store.selectSnapshot(AppState.currentUser);
    return this.identity;
  }
}
