import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-export-chat-option-dialog',
  templateUrl: './export-chat-option-dialog.component.html',
  styleUrls: ['./export-chat-option-dialog.component.scss']
})
export class ExportChatOptionDialogComponent implements OnInit {
  public dataForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ExportChatOptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dataForm = new FormGroup({
      start_date: new FormControl(null, [Validators.required]),
      end_date: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onOkClick(): void {
    const formValue = this.dataForm.value;
    if (formValue.start_date) {
    }
    this.dialogRef.close(true);
  }

}
