import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { environment } from '@env/environment';
import { SentryService } from './sentry.service';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FDTHelper } from '@app/shared/helpers/helpers';

import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class OcidService {

  private baseUrl: string;
  private identity: any;

  constructor(
    private http: HttpClient,
    private store: Store,
    private router: Router,
  ) {
    this.baseUrl = environment.apiDomain;
  }

  private handleError(error: HttpErrorResponse) {
    return FDTHelper.handleError('ocid', this.store, this.router, error);
    /* SentryService.handleError(error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // check code
      if (error.status >= 400) {
        if (error.error.message) {
          return throwError(error.error.message);
        }
      } else {
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${JSON.stringify(error.error)}`);
      }
    }
    // return an observable with a user-facing error message
    return throwError(
      'มีข้อผิดพลาดเกิดขึ้นในการเชื่อมต่อระบบ กรุณาลองใหม่ในอีกสักครู่ [ocid]'
      // 'Something bad happened; please try again later.'
    ); */
  }

  checkLoginSession() {
    const url = environment.rocheConnectProbeUrl;
    return this.http.get(url).pipe(
      catchError(this.handleError.bind(this))
    );
  }

  init(payload) {
    const url = `${this.baseUrl}/ocid/init?t=${moment().format('X')}`;
    return this.http.post(url, payload, {
        headers: {
          Authorization: `Bearer ${environment.OCID_API_KEY}`,
        }
      }).pipe(
        catchError(this.handleError.bind(this))
      );
  }
}
