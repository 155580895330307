<h1
  mat-dialog-title
  [ngClass]="mainTitleCssClass"
>
  {{ data.dialogTitle }}
</h1>
<div mat-dialog-content [innerHtml]="data.dialogBody">
</div>
<div
  mat-dialog-actions
  class="msq-dialog-actions"
>
  <button
    type="button"
    class="btn btn-light dialog-action-button"
    (click)="onNoClick()"
  >
    {{ cancelButtonText }}
  </button>
  <button
    type="button"
    class="btn dialog-action-button"
    [ngClass]="mainButtonCssClass"
    (click)="onOkClick()"
    cdkFocusInitial
  >
    {{ mainButtonText }}
  </button>
</div>