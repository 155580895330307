<div class="app-container">
  <!-- Sidebar -->
  <nav
    id="sidebar"
    class="active"
    *ngIf="shouldShowGlobalHeader"
    [ngClass]="{
      active: !shouldShowSideBar
    }"
  >
    <!--<div class="sidebar-header">
        <h3>{{ appName }} Admin</h3>
    </div>-->

    <ul class="sidebar-menu list-unstyled components">
      <li class="menu-item">
        <a
          class="menu-link"
          [routerLink]="['/admin/home']"
          routerLinkActive="link-active"
        >
          <i class="fa fa-home"></i>&nbsp;&nbsp;
          Home
        </a>
      </li>
      <li class="section">
        <a
          href="#hcpSubmenu"
          data-toggle="collapse"
          aria-expanded="false"
          class="section-title dropdown-toggle"
        >
          <span>
            <i class="fa fa-user-md"></i>&nbsp;&nbsp;
            HCP Users
          </span>
        </a>
        <ul class="collapse list-unstyled show submenu" id="hcpSubmenu">
          <ng-container
            *ngIf="isAdmin"
          >
            <li class="menu-item">
              <a
                class="menu-link"
                [routerLink]="['/admin/hcp/list', 'ALL']"
                routerLinkActive="link-active"
              >
                <i class="fa fa-list-ul"></i>&nbsp;&nbsp;
                All
              </a>
            </li>
            <li class="menu-item">
              <a
                class="menu-link"
                [routerLink]="['/admin/hcp/list', 'PENDING_REGISTER']"
                routerLinkActive="link-active"
              >
                <i class="fa fa-clock-o"></i>&nbsp;&nbsp;
                Pending Register
              </a>
            </li>
            <li class="menu-item">
              <a
                class="menu-link"
                [routerLink]="['/admin/hcp/list', 'PENDING_CONFIRM']"
                routerLinkActive="link-active"
              >
                <i class="fa fa-clock-o"></i>&nbsp;&nbsp;
                Pending Confirm
              </a>
            </li>
            <!-- <li class="menu-item">
              <a
                class="menu-link"
                [routerLink]="['/admin/hcp/list', 'VERIFY_EMAIL_NOTIFIED']"
                routerLinkActive="link-active"
              >
                <i class="fa fa-envelope"></i>&nbsp;&nbsp;
                Verify Email Notified
              </a>
            </li> -->
            <li class="menu-item">
              <a
                class="menu-link"
                [routerLink]="['/admin/hcp/list', 'ACTIVATION_NOTIFIED']"
                routerLinkActive="link-active"
              >
                <i class="fa fa-bolt"></i>&nbsp;&nbsp;
                Activation Notified
              </a>
            </li>
            <li class="menu-item">
              <a
                class="menu-link"
                [routerLink]="['/admin/hcp/list', 'CONFIRMED']"
                routerLinkActive="link-active"
              >
                <i class="fa fa-check-circle"></i>&nbsp;&nbsp;
                Confirmed
              </a>
            </li>
            <li class="menu-item">
              <a
                class="menu-link"
                [routerLink]="['/admin/hcp/list', 'REJECTED']"
                routerLinkActive="link-active"
              >
                <i class="fa fa-times-circle"></i>&nbsp;&nbsp;
                Rejected
              </a>
            </li>
          </ng-container>
          <ng-container
            *ngIf="isExpert"
          >
            <li class="menu-item">
              <a
                class="menu-link"
                [routerLink]="['/admin/hcp/list', 'CONFIRMED']"
                routerLinkActive="link-active"
              >
                <i class="fa fa-check-circle"></i>&nbsp;&nbsp;
                View
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
      <li class="section">
        <a
          href="#hcpRequestSubmenu"
          data-toggle="collapse"
          aria-expanded="false"
          class="section-title dropdown-toggle"
        >
          <span>
            <i class="fa fa-user-md"></i>&nbsp;&nbsp;
            HCP Requests
          </span>
        </a>
        <ul class="collapse list-unstyled show submenu" id="hcpRequestSubmenu">
          <ng-container
            *ngIf="isAdmin"
          >
            <li class="menu-item">
              <a
                class="menu-link"
                [routerLink]="['/admin/hcp-request/ask-publication']"
                routerLinkActive="link-active"
              >
                <i class="fa fa-files-o"></i>&nbsp;&nbsp;
                Ask Publications
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
      <li
        class="section"
      >
        <a
          href="#lineChatMenuSubmenu"
          data-toggle="collapse"
          aria-expanded="false"
          class="section-title dropdown-toggle"
        >
          <span>
            <i class="fa fa-comments"></i>&nbsp;&nbsp;
            LINE Chats
          </span>
        </a>
        <ul class="collapse list-unstyled show submenu" id="lineChatMenuSubmenu">
          <li
            *ngIf="isExpert"
            class="menu-item"
          >
            <a
              class="menu-link"
              [routerLink]="['/admin/chats']"
              routerLinkActive="link-active"
            >
              <i class="fa fa-comments"></i>&nbsp;&nbsp;
              All Chats
            </a>
          </li>
          <li
            *ngIf="isAdmin"
            class="menu-item"
          >
            <a
              class="menu-link"
              href="javascript:void(0);"
              (click)="exportChats()"
            >
              <i class="fa fa-comments"></i>&nbsp;&nbsp;
              Export Chats
            </a>
          </li>
          <!--
          <li class="menu-item">
            <a
              class="menu-link"
              [routerLink]="['/admin/line/chat/INBOX']"
              routerLinkActive="link-active"
            >
              <i class="fa fa-inbox"></i>&nbsp;&nbsp;
              Inbox
            </a>
          </li>
          <li class="menu-item">
            <a
              class="menu-link"
              [routerLink]="['/admin/line/chat/UNREAD']"
              routerLinkActive="link-active"
            >
              <i class="fa fa-envelope"></i>&nbsp;&nbsp;
              Unread
            </a>
          </li>
          <li class="menu-item">
            <a
              class="menu-link"
              [routerLink]="['/admin/line/chat/FOLLOW_UP']"
              routerLinkActive="link-active"
            >
              <i class="fa fa-mail-reply"></i>&nbsp;&nbsp;
              Follow-up
            </a>
          </li>
          <li class="menu-item">
            <a
              class="menu-link"
              [routerLink]="['/admin/line/chat/RESOLVED']"
              routerLinkActive="link-active"
            >
              <i class="fa fa-check"></i>&nbsp;&nbsp;
              Resolved
            </a>
          </li>
          -->
        </ul>
      </li>
      <!--
      <li
        class="section"
      >
        <a
          href="#lineMenuSubmenu"
          data-toggle="collapse"
          aria-expanded="false"
          class="section-title dropdown-toggle"
        >
          <span>
            <i class="fa fa-comment"></i>&nbsp;&nbsp;
            LINE CONFIG
          </span>
        </a>
        <ul class="collapse list-unstyled show submenu" id="lineMenuSubmenu">
          <li class="menu-item">
            <a
              class="menu-link"
              [routerLink]="['/admin/line-rich-menu/list']"
              routerLinkActive="link-active"
            >
              <i class="fa fa-th-large"></i>&nbsp;&nbsp;
              Rich Menu
            </a>
          </li>
          <li class="menu-item">
            <a
              class="menu-link"
              [routerLink]="['/admin/line-links/list']"
              routerLinkActive="link-active"
            >
              <i class="fa fa-link"></i>&nbsp;&nbsp;
              Links
            </a>
          </li>
          <li class="menu-item">
            <a
              class="menu-link"
              [routerLink]="['/admin/pdf-viewer/list']"
              routerLinkActive="link-active"
            >
              <i class="fa fa-cube"></i>&nbsp;&nbsp;
              PDF Viewer LIFF
            </a>
          </li>
          <li class="menu-item">
            <a
              class="menu-link"
              [routerLink]="['/admin/drug-availability/list']"
              routerLinkActive="link-active"
            >
              <i class="fa fa-cube"></i>&nbsp;&nbsp;
              Drug Availability LIFF
            </a>
          </li>
          <li class="menu-item">
            <a
              class="menu-link"
              [routerLink]="['/admin/sales-rep/list']"
              routerLinkActive="link-active"
            >
              <i class="fa fa-cube"></i>&nbsp;&nbsp;
              Sales Rep. LIFF
            </a>
          </li>
          <li class="menu-item">
            <a
              class="menu-link"
              [routerLink]="['/admin/sales-rep/list']"
              routerLinkActive="link-active"
            >
              <i class="fa fa-cog"></i>&nbsp;&nbsp;
              Bot Config
            </a>
          </li>
        </ul>
      </li>
      -->
      <!-- PDF CONTENT -->
      <li
        *ngIf="isAdmin || isExpert"
        class="section"
      >
        <a
          href="#pdfContentSubmenu"
          data-toggle="collapse"
          aria-expanded="false"
          class="section-title dropdown-toggle"
        >
          <span>
            <i class="fa fa-file-pdf-o"></i>&nbsp;&nbsp;
            PDF CONTENT
          </span>
        </a>
        <ul class="collapse list-unstyled show submenu" id="pdfContentSubmenu">
          <li class="menu-item">
            <a
              class="menu-link"
              [routerLink]="['/admin/pdf-content/manage']"
              routerLinkActive="link-active"
            >
              <i class="fa fa-file-pdf-o"></i>&nbsp;&nbsp;
              <ng-container
                *ngIf="isAdmin"
              >
              Manage
              </ng-container>
              <ng-container
                *ngIf="isExpert"
              >
              View
              </ng-container>
            </a>
          </li>
        </ul>
      </li>
      <!-- ADMIN STUFFS -->
      <li
        *ngIf="isAdmin"
        class="section"
      >
        <a
          href="#userSubmenu"
          data-toggle="collapse"
          aria-expanded="false"
          class="section-title dropdown-toggle"
        >
          <span>
            <i class="fa fa-user-secret"></i>&nbsp;&nbsp;
            Agent Users
          </span>
        </a>
        <ul class="collapse list-unstyled show submenu" id="userSubmenu">
          <li class="menu-item">
            <a
              class="menu-link"
              [routerLink]="['/admin/users/list']"
              routerLinkActive="link-active"
            >
              <i class="fa fa-users"></i>&nbsp;&nbsp;
              Manage
            </a>
          </li>
        </ul>
      </li>
      <li
        class="section"
      >
        <a
          href="#toolSubmenu"
          data-toggle="collapse"
          aria-expanded="false"
          class="section-title dropdown-toggle"
        >
          <span>
            <i class="fa fa-wrench"></i>&nbsp;&nbsp;
            Tools
          </span>
        </a>
        <ul class="collapse list-unstyled show submenu" id="toolSubmenu">
          <li class="menu-item">
            <a
              class="menu-link"
              [routerLink]="['/admin/chats/tool-url-gen']"
              routerLinkActive="link-active"
            >
              <i class="fa fa-link"></i>&nbsp;&nbsp;
              Portal URL Generator
            </a>
          </li>
        </ul>
      </li>
      <!-- LOGOUT -->
      <li class="menu-item last-item">
        <a
          class="menu-link text-danger"
          [routerLink]="['/auth/logout']"
          routerLinkActive="link-active"
        >
          <i class="fa fa-sign-out"></i>&nbsp;&nbsp;
          Logout
        </a>
      </li>
    </ul>

  </nav>
  <!-- Page Content -->
  <div
    id="content"
    [ngClass]="{
      active: !shouldShowSideBar
    }"
  >
    <header id="app-header" *ngIf="shouldShowGlobalHeader">
      <nav class="navbar navbar-expand-lg navbar-light">
        <button
          class="btn btn-link"
          type="button"
          (click)="toggerSideBar()"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" role="img" focusable="false"><title>Menu</title><path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M4 7h22M4 15h22M4 23h22"></path></svg>
        </button>
        <a class="navbar-brand" routerLink="/">
          {{ appName }} Administration
        </a>
        <div id="global-logo"></div>
      </nav>
    </header>
    <router-outlet></router-outlet>
  </div>
</div>
<ngx-spinner
  bdOpacity="0.5"
  bdColor="rgba(255,255,255,0.8)"
  size="default"
  color="#0066cc"
  type="ball-clip-rotate"
  [fullScreen]="true"
>
  <br>
  <p style="color: #0066cc">
    <strong>
      {{ globalSpinnerMessage }}
    </strong>
  </p>
</ngx-spinner>
