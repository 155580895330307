<h1
  mat-dialog-title
>
  Export Chat Options
</h1>
<div mat-dialog-content>
  <form [formGroup]="dataForm">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label class="form-label">Start From</label>
            <input
              autocomplete="off"
              name="start_date"
              formControlName="start_date"
              readonly
              matInput
              (focus)="startDateDatePicker.open()"
              (click)="startDateDatePicker.open()"
              placeholder="select start date"
            >
            <mat-datepicker
              #startDateDatePicker></mat-datepicker>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div
  mat-dialog-actions
  class="msq-dialog-actions"
>
  <button
    type="button"
    class="btn btn-light dialog-action-button"
    (click)="onNoClick()"
  >
    Cancel
  </button>
  <button
    type="button"
    class="btn dialog-action-button"
    (click)="onOkClick()"
    cdkFocusInitial
  >
    Export
  </button>
</div>