import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmDialogData {
  dialogType: string;
  dialogTitle: string;
  dialogBody: string;
  dialogMainButtonText: string;
  dialogCancelButtonText: string;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  public dialogType = 'CONFIRM';
  public cancelButtonText = 'ยกเลิก';
  public mainButtonText = 'ตกลง';
  private allowedTypes = ['CONFIRM', 'SUCCESS', 'DANGER', 'WARNING', 'LIGHT'];

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {
    if (this.allowedTypes.indexOf(data.dialogType)) {
      this.dialogType = data.dialogType;
    }
    if (data.dialogMainButtonText) {
      this.mainButtonText = data.dialogMainButtonText;
    }
    if (data.dialogCancelButtonText) {
      this.cancelButtonText = data.dialogCancelButtonText;
    }
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onOkClick(): void {
    this.dialogRef.close(true);
  }

  get mainTitleCssClass() {
    if (this.dialogType === 'SUCCESS') {
      return '-success';
    } else if (this.dialogType === 'DANGER') {
      return '-danger';
    } else if (this.dialogType === 'WARNING') {
      return '-warning';
    } else if (this.dialogType === 'LIGHT') {
      return '-light';
    }
    return '-confirm';
  }

  get mainButtonCssClass() {
    if (this.dialogType === 'SUCCESS') {
      return 'btn-success';
    } else if (this.dialogType === 'DANGER') {
      return 'btn-danger';
    } else if (this.dialogType === 'WARNING') {
        return 'btn-warning';
    } else if (this.dialogType === 'LIGHT') {
      return 'btn-light';
    }
    return 'btn-primary';
  }

}
