import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '', // go to login page
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('@app/modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'hcp',
    loadChildren: () => import('@app/modules/hcp/hcp.module').then(m => m.HcpModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('@app/modules/admin/admin.module').then(m => m.AdminModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
